import { Injectable } from '@angular/core';
import { RollbarService } from '@becksdevteam/rollbar-angular';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as globalActions from '../actions';
import * as userActions from '../actions/user.action';

import { UserService } from '../../shared/services/user.service';

@Injectable()
export class UserEffects {
  constructor(
    private _actions$: Actions,
    private _userService: UserService,
    private _rollbarService: RollbarService
  ) {}

  userLoaded$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.INITIALIZE_USER),
      map((action: globalActions.InitializeUser) => action.payload),
      filter((user) => !!user.email),
      map((user) => {
        return new userActions.InitializeUserSuccess(user);
      }),
      catchError((error) => {
        return of(new userActions.InitializeUserFail(error));
      })
    )
  );

  setErrorLogUser$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(globalActions.INITIALIZE_USER_SUCCESS),
        map((action: globalActions.InitializeUserSuccess) => action.payload),
        tap((user) => {
          this._rollbarService.configure({
            payload: {
              person: {
                id: user.id!,
                username: user.userName,
                email: user.email
              }
            }
          });
        })
      ),
    { dispatch: false }
  );

  loadUserHomeWarehouseInfo$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.LOAD_USER_HOME_WAREHOUSE_INFO),
      map((action: globalActions.LoadUserHomeWarehouseInfo) => action.payload),
      switchMap((user) =>
        (user.type === 'AD_INTERNAL'
          ? this._userService.fetchUserHomeWarehouseInternal()
          : this._userService.fetchUserHomeWarehouseExternal(user.activeCustID!)
        ).pipe(
          map((userHomeInfo) => {
            return new userActions.LoadUserHomeWarehouseInfoSuccess(userHomeInfo);
          }),
          catchError((error) => {
            return of(new userActions.LoadUserHomeWarehouseInfoFail(error));
          })
        )
      )
    )
  );
}
