import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { NgClass, NgIf } from '@angular/common';
import { LoadingIndicatorComponent } from 'app/shared/components/loading-indicator/bng-loading-indicator/bng-loading-indicator.component';
import { windowToken } from 'app/shared/window-token';
import { combineLatest } from 'rxjs';

type IframeMessage =
  | {
      initialLoad: boolean;
    }
  | {
      path: string;
    }
  | { navigateTo: string };

@Component({
  selector: 'bng-iframe-wrapper',
  template: `
    <div class="w-full min-h-0 h-full max-w-full relative">
      <bng-loading-indicator [loading]="!iframeLoaded"></bng-loading-indicator>
      <iframe
        *ngIf="!!safeUrl"
        [src]="safeUrl"
        frameborder="0"
        [ngClass]="{ hidden: !iframeLoaded, 'w-full min-h-0 h-full max-w-full relative overflow-auto': true }"
      ></iframe>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, LoadingIndicatorComponent]
})
export class IframeWrapperComponent implements OnInit, OnDestroy {
  safeUrl: SafeResourceUrl | null = null;
  iframeLoaded: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    @Inject(windowToken) private window: Window,
    private router: Router
  ) {}

  ngOnInit() {
    this.iframeLoaded = false;
    combineLatest([this.activatedRoute.data, this.activatedRoute.params, this.activatedRoute.queryParams])
      .pipe(take(1))
      .subscribe(([data, params, queryParams]) => {
        const authToken = data['authToken'];
        const oktaUserId = data['oktaUserId'];
        const reactBaseUrl = data['reactBaseUrl'];
        const url = data['url']({ params, queryParams });
        const angularUrl = this.buildAngularUrl(this.router.url);
        const internalIframeRemixUrl = queryParams['reactUrl'] || this.window.sessionStorage.getItem('x-remix-internal-url') || url;

        if (queryParams['reactUrl']) {
          // Need setTimeout to prevent ExpressionChangedAfterItHasBeenCheckedError
          setTimeout(() => {
            // Remove reactUrl to prevent it sticking around on routes that merge queryParams
            const { reactUrl: _reactUrl, ...queryParamsWithoutReactUrl } = queryParams;
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: queryParamsWithoutReactUrl,
              replaceUrl: true
            });
          });
        }

        const fullUrl = this.buildUrl({
          reactBaseUrl,
          url: internalIframeRemixUrl,
          authToken,
          oktaUserId,
          angularUrl
        });
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl);
      });

    this.window.addEventListener('message', this.handleMessage, false);
  }

  ngOnDestroy(): void {
    this.window.removeEventListener('message', this.handleMessage);
    this.window.sessionStorage.removeItem('x-remix-internal-url');
  }

  handleMessage = (event: MessageEvent<IframeMessage>) => {
    const domainRegex = /https?:\/\/(.*\.)?beckshybrids\.com(?::(3000|4200))?$/;

    //stop cross site scripting attempts
    if (!domainRegex.test(event.origin)) {
      // console.log('Origin not allowed:', event.origin);
      return;
    }

    if ('path' in event.data) {
      const newPath = event.data.path;
      this.window.sessionStorage.setItem('x-remix-internal-url', newPath);
    }
    if ('initialLoad' in event.data) {
      this.iframeLoaded = true;
      this.cdr.detectChanges();
    }
    if ('navigateTo' in event.data) {
      this.router.navigateByUrl(event.data.navigateTo);
    }
  };

  buildUrl({
    reactBaseUrl,
    url,
    authToken,
    oktaUserId,
    angularUrl
  }: {
    reactBaseUrl: string;
    url: string;
    authToken: string;
    oktaUserId: string;
    angularUrl: string;
  }): string {
    const urlObj = new URL(url, reactBaseUrl);
    // Get any search params associated with the iframeUrl, excluding authToken, oktaUserId, angularUrl and iframeTargetUrl
    const searchParams = new URLSearchParams((url || '').split('?')[1] || '');
    searchParams.delete('authToken');
    searchParams.delete('oktaUserId');
    searchParams.delete('iframeTargetUrl');
    searchParams.delete('angularUrl');

    // Build iframeTargetUrl searchParam excluding authToken, oktaUserId, angularUrl and iframeTargetUrl
    const searchParamsString = searchParams.size > 0 ? `?${searchParams.toString()}` : '';
    const iFrameUrl = `${urlObj.pathname}${searchParamsString}`;

    // Add authToken, oktaUserId, angularUrl and iframeTargetUrl to search params for full iframeUrl
    // This is done at the end to authToken, oktaUserId, angularUrl and iframeTargetUrl from being duplicated in the url
    searchParams.append('authToken', authToken);
    searchParams.append('oktaUserId', oktaUserId);
    searchParams.append('iframeTargetUrl', iFrameUrl);
    searchParams.append('angularUrl', angularUrl);

    return `${urlObj.origin}${urlObj.pathname}?${searchParams.toString()}`;
  }

  private buildAngularUrl(angularUrl: string): string {
    const angularUrlSplit = angularUrl.split('?');
    const angularUrlSearchParams = new URLSearchParams(angularUrlSplit[1] || '');
    angularUrlSearchParams.delete('reactUrl');

    return `${angularUrlSplit[0]}?${angularUrlSearchParams.toString()}`;
  }
}
