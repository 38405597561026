import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentInjector, NgModule, NgZone, inject, runInInjectionContext } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthRequiredFunction,
  BECKS_OKTA_LOGIN_DISABLED,
  BECKS_OKTA_LOGIN_MESSAGE,
  IBecksMessage,
  IOktaConfig,
  IOktaSession,
  OktaAuthHttpInterceptor,
  OktaAuthModule,
  OktaAuthService,
  SameSessionCheckFunction,
  UserForbiddenFunction,
  UserNotFoundFunction
} from '@becksdevteam/okta-angular';

import config from 'app/app.config';
import { StartupService } from 'app/core/services/startup.service';
import { IUser } from 'app/shared/models/user.interface';
import { SnackbarService } from 'app/shared/services/snackbar.service';
import { UserService } from 'app/shared/services/user.service';
import { AuthRoutingModule } from './auth.routing';
import { AuthService } from './auth.service';

const appBaseUrl = config.bossUrl;
const redirectUri = `${appBaseUrl}/implicit/callback`;

const onAuthRequired: AuthRequiredFunction = (_oktaService: OktaAuthService, environmentInjector: EnvironmentInjector) => {
  runInInjectionContext(environmentInjector, () => {
    const ngZone = inject(NgZone);
    ngZone.run(() => {
      // send user to login
      const userService = inject(UserService);
      userService.signout();
    });
  });
};

const onUserNotFound: UserNotFoundFunction = (_oktaService: OktaAuthService, environmentInjector: EnvironmentInjector) => {
  runInInjectionContext(environmentInjector, () => {
    const ngZone = inject(NgZone);
    ngZone.run(() => {
      // send user to create new user page
      const router = inject(Router);
      router.navigate(['/createUser']);
    });
  });
};

const onUserForbidden: UserForbiddenFunction = (_oktaService: OktaAuthService, environmentInjector: EnvironmentInjector) => {
  runInInjectionContext(environmentInjector, () => {
    const ngZone = inject(NgZone);
    ngZone.run(() => {
      // display message
      const snackbarService = inject(SnackbarService);
      snackbarService.error('You do not have access to BOSS: Please, contact the Help Desk.');

      // send user to login
      const router = inject(Router);
      router.navigate(['/login']);
    });
  });
};

const isSameSession: SameSessionCheckFunction = async (beckUser: IUser, oktaSession: IOktaSession) => {
  return beckUser.okta?.external.sessionID === oktaSession.id;
};

const oktaConfig: IOktaConfig = {
  auth: {
    issuer: `${config.okta.externalOrgUrl}/oauth2/default`,
    clientId: config.okta.clientId,
    redirectUri,
    postLogoutRedirectUri: appBaseUrl
  },
  widget: {
    baseUrl: config.okta.externalOrgUrl,
    redirectUri,
    features: {
      idpDiscovery: true,
      registration: true
    }
  },
  appBaseUrl,
  authToken: config.authTokenName,
  endpoints: {
    baseUrl: config.apiUrl,
    session: {
      validate: `/auth/verifyOktaSession`,
      terminate: `/auth/terminateOktaSession`,
      getInternal: `${config.okta.internalOrgUrl}/api/v1/sessions/me`
    },
    token: {
      verify: `${config.authApiUrl}/tokens/apps/verify`
    },
    credentials: {
      login: `/auth/updateLogin`,
      password: `/auth/updatePassword`
    }
  },
  isSameSession,
  onAuthRequired,
  onUserNotFound,
  onUserForbidden
};

@NgModule({
  imports: [OktaAuthModule.forRoot(oktaConfig), AuthRoutingModule],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OktaAuthHttpInterceptor,
      multi: true
    },
    {
      provide: BECKS_OKTA_LOGIN_DISABLED,
      useFactory: (startupService: StartupService) => startupService.getAccessIsDisabled(),
      deps: [StartupService]
    },
    {
      provide: BECKS_OKTA_LOGIN_MESSAGE,
      useFactory: (startupService: StartupService) => {
        const maintenance = startupService.getMaintenanceStatus();

        if (!maintenance.isEnabled) {
          return;
        }

        const message: IBecksMessage = {
          title: maintenance.title,
          description: maintenance.message
        };

        return message;
      },
      deps: [StartupService]
    }
  ]
})
export class AuthModule {}
