import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { OktaAuthService } from '@becksdevteam/okta-angular';
import { from } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { IUser } from '../models/user.interface';
import { UserService } from '../services/user.service';

export const userGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const oktaService = inject(OktaAuthService<IUser>);
  const userService = inject(UserService);

  return oktaService.isAuthenticated$.pipe(
    // only pass when user is authenticated
    filter((isAuthenticated) => isAuthenticated),
    // perform necessary loads
    switchMap(() => from(getUser(oktaService, userService)))
  );
};

const getUser = async (oktaService: OktaAuthService<IUser>, userService: UserService): Promise<boolean> => {
  try {
    // get user from a valid session
    const user = await oktaService.getUser();

    // useful to react
    // not used in angular
    if (user?.okta?.userID) {
      window.sessionStorage.setItem('x-okta-user-id', user.okta.userID);
    }

    // Authorization - initialize user
    userService.initUser(user);

    return true;
  } catch (err) {
    return false;
  }
};
