import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/** Components */
import { ImplicitCallbackComponent, LoginComponent, canActivateOktaProtectLogin } from '@becksdevteam/okta-angular';
import appConfig from 'app/app.config';
import { LogoutComponent } from './components/logout.component';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
    canActivate: [canActivateOktaProtectLogin]
  },
  {
    path: 'logout',
    pathMatch: 'full',
    data: {
      iframeAngularLink: `/logout`,
      reactBaseUrl: appConfig.reactClientUrl,
      url: () => `/r/iframe-logout`
    },
    component: LogoutComponent
  },
  {
    path: 'implicit/callback',
    pathMatch: 'full',
    component: ImplicitCallbackComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
