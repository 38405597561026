import { Component } from '@angular/core';
import { OktaAuthModule } from '@becksdevteam/okta-angular';
import { IframeWrapperComponent } from 'app/shared/components/iframe-wrapper/iframe-wrapper.component';

@Component({
  selector: 'bng-logout',
  template: `<becks-okta-logout></becks-okta-logout>
    <div class="hidden">
      <bng-iframe-wrapper></bng-iframe-wrapper>
    </div>`,
  standalone: true,
  imports: [OktaAuthModule, IframeWrapperComponent]
})
export class LogoutComponent {}
