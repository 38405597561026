import { Injectable } from '@angular/core';
import { IUser } from 'app/shared/models/user.interface';
import { UserService } from 'app/shared/services/user.service';

@Injectable()
export class AuthService {
  user: IUser | undefined;

  constructor(private _userService: UserService) {
    this._userService.getCurrentUser().subscribe((u) => {
      this.user = u;
    });
  }

  isAuthorizedForRole(role: string) {
    if (this.user && this.user.securityObjects) {
      return UserService.hasRole(this.user, role);
    } else {
      return false;
    }
  }
}
