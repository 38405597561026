export interface IUser {
  id?: number;
  beckUserID?: number;
  dsmID?: number;
  atlID?: number;
  rbmID?: number;
  authToken?: string;
  email: string;
  expires?: number;
  firstName: string;
  lastName: string;
  type?: UserType;
  userName: string;
  userStatusExp?: number;
  pictureUrl: string;
  lastUpdated?: string;
  securityObjects?: IFeatureSet[];
  activeCustID?: number;
  activeCustBeckType?: string;
  okta?: IOktaDetails;
  appAccess?: string[];
  iat?: number;
  salesRepDlrID?: number;
}

export interface IOktaDetails {
  userID: string;
  external: IOktaOrgSession;
  internal?: IOktaOrgSession;
}

export interface IOktaOrgSession {
  sessionID: string;
  sessionCreatedAt: Date;
}

export interface IUserPermissions {
  id: number;
  userName: string;
  customerID: number;
  email: string;
  firstName: string;
  lastName: string;
  organization: string;
  securityObjects: object[];
  featureIDs: number[];
  featureSetIDs: number[];
  fineTuning?: IFeature[];
  pictureUrl: string;
  lastUpdated: string;
  newUser: boolean;
}

export enum UserType {
  AD_INTERNAL = 'AD_INTERNAL',
  AD_EXTERNAL = 'AD_EXTERNAL'
}

export enum UserRole {
  RBM = 'RBM',
  ATL = 'ATL',
  SA = 'SA',
  INTERNAL_USER = 'INTERNAL_USER',
  DEALER = 'DEALER',
  CUSTOMER = 'CUSTOMER'
}

export interface IFeatureSet {
  id: number;
  code: string;
  desc: string;
  features: IFeature[];
  listOrder?: number;
  collapsed?: boolean;
}

export interface IFeature {
  id: number;
  code: string;
  desc: string;
  disabled?: boolean;
  listOrder?: number;
  optional?: boolean;
  checked?: boolean;
}

export interface IExternalUser extends IUser {
  ID: number;
  defaultCustID: number;
  salesRepDlrID: number;
  email: string;
  enabled: boolean;
  name: string;
}

export interface IUserPreferenceSettingType {
  ID: number;
  code: string;
  desc: string;
}
export interface IUserPreference<T = any> {
  beckUserID: number;
  settingType: IUserPreferenceSettingType;
  settingValue: T | null;
  salesYear?: string;
}
export enum UserPreferenceSettingTypeCode {
  PROD_FILTERS = 'PRODFILTERS',
  DEF_QUOTE_NOTE = 'DEFQUOTENOTE',
  LB_WAREHOUSES = 'LBWAREHOUSES',
  BP_CUSTOMERS_FILTERS = 'BPCUSTFILTERS',
  BP_PROSPECTS_FILTERS = 'BPPROSFILTERS'
}

export interface IHomeWarehouse {
  warehouseCustID: number;
  warehouseID: string;
  description: string;
  msrepl_tran_version: string;
  location: string;
  abbreviation: string;
  latitude: number;
  longitude: number;
  homeWarehouseDescription: string;
  alias: string;
  phoneNumber: string;
}

export interface IAgent {
  date: Date;
  userName: string;
  firstName: string;
  lastName: string;
  defaultOrg: string;
}
