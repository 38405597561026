import { IHomeWarehouse, IUser } from 'app/shared/models/user.interface';

import * as fromUser from '../actions/user.action';

// REFERENCE:  https://github.com/palantir/tslint/issues/1063

const defaultUser: IUser = {
  email: '',
  firstName: '',
  lastName: '',
  pictureUrl: '',
  userName: ''
};

const defaultWarehouse: IHomeWarehouse = {
  warehouseCustID: 0,
  warehouseID: '',
  description: '',
  msrepl_tran_version: '',
  location: '',
  abbreviation: '',
  latitude: 0,
  longitude: 0,
  homeWarehouseDescription: '',
  alias: '',
  phoneNumber: ''
};

export interface UserState {
  security: any;
  user: IUser;
  homeWarehouse: IHomeWarehouse;
  userLoaded: boolean;
  userLoading: boolean;
  warehouseLoaded: boolean;
  warehouseLoading: boolean;
}

const initialState: UserState = {
  security: {},
  user: defaultUser,
  homeWarehouse: defaultWarehouse,
  userLoaded: false,
  userLoading: false,
  warehouseLoaded: false,
  warehouseLoading: false
};

export const userStateKey = 'user';
export function userReducer(state = initialState, action: fromUser.UserAction): UserState {
  switch (action.type) {
    // user object
    case fromUser.INITIALIZE_USER: {
      return {
        ...state,
        userLoading: true
      };
    }
    case fromUser.INITIALIZE_USER_FAIL: {
      return {
        ...state,
        userLoaded: false,
        userLoading: false
      };
    }
    case fromUser.INITIALIZE_USER_SUCCESS: {
      const user = action.payload;
      return {
        ...state,
        userLoaded: true,
        userLoading: false,
        user,
        security: user.securityObjects
      };
    }
    case fromUser.LOAD_USER_HOME_WAREHOUSE_INFO: {
      return {
        ...state,
        warehouseLoading: true,
        warehouseLoaded: false
      };
    }
    case fromUser.LOAD_USER_HOME_WAREHOUSE_INFO_FAIL: {
      return {
        ...state,
        warehouseLoading: false,
        warehouseLoaded: false
      };
    }
    case fromUser.LOAD_USER_HOME_WAREHOUSE_INFO_SUCCESS: {
      const userWarehouseInfo = action.payload;
      return {
        ...state,
        warehouseLoaded: true,
        warehouseLoading: false,
        homeWarehouse: userWarehouseInfo
      };
    }
  }

  return state;
}
