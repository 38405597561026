import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { OktaAuthService } from '@becksdevteam/okta-angular';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import * as fromStore from '../../store';

export const globalGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return checkSalesYearsLoaded().pipe(
    switchMap(() => of(true)),
    catchError(() => of(false))
  );
};

const checkSalesYearsLoaded = (): Observable<boolean> => {
  const oktaAuthService = inject(OktaAuthService);
  const store = inject(Store<fromStore.State>);

  return oktaAuthService.isAuthenticated$.pipe(
    // only pass when user is authenticated
    filter((isAuthenticated) => isAuthenticated),
    // perform necessary loads
    switchMap(() => checkStore(store))
  );
};

const checkStore = (store: Store<fromStore.State>): Observable<boolean> => {
  return store.select(fromStore.getSalesYearsLoaded).pipe(
    tap((loaded) => {
      if (!loaded) {
        store.dispatch(fromStore.loadSalesYears());
      }
    }),
    filter((loaded) => loaded),
    take(1)
  );
};
