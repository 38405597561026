import { Action } from '@ngrx/store';

import { IUser, IHomeWarehouse } from 'app/shared/models/user.interface';

export const INITIALIZE_USER = '[Global] Initialize User';
export const INITIALIZE_USER_FAIL = '[Global] Initialize User Fail';
export const INITIALIZE_USER_SUCCESS = '[Global] Initialize User Success';

export class InitializeUser implements Action {
  readonly type = INITIALIZE_USER;
  constructor(public payload: IUser) {}
}
export class InitializeUserFail implements Action {
  readonly type = INITIALIZE_USER_FAIL;
  constructor(public payload: any) {}
}
export class InitializeUserSuccess implements Action {
  readonly type = INITIALIZE_USER_SUCCESS;
  constructor(public payload: IUser) {}
}

export const LOAD_SECURITY_OBJECTS = '[Global] Load Security Objects';
export const LOAD_SECURITY_OBJECTS_FAIL = '[Global] Load Security Objects Fail';
export const LOAD_SECURITY_OBJECTS_SUCCESS = '[Global] Load Security Objects Success';

export class LoadSecurityObjects implements Action {
  readonly type = LOAD_SECURITY_OBJECTS;
  constructor(public payload: IUser) {}
}
export class LoadSecurityObjectsFail implements Action {
  readonly type = LOAD_SECURITY_OBJECTS_FAIL;
  constructor(public payload: any) {}
}
export class LoadSecurityObjectsSuccess implements Action {
  readonly type = LOAD_SECURITY_OBJECTS_SUCCESS;
  constructor(public payload: any) {}
}

export const LOAD_USER_HOME_WAREHOUSE_INFO = '[Global] Load User Home Warehouse Info';
export const LOAD_USER_HOME_WAREHOUSE_INFO_FAIL = '[Global] Load User Home Warehouse Info Fail';
export const LOAD_USER_HOME_WAREHOUSE_INFO_SUCCESS = '[Global] Load User Home Warehouse Info Success';

export class LoadUserHomeWarehouseInfo implements Action {
  readonly type = LOAD_USER_HOME_WAREHOUSE_INFO;
  constructor(public payload: IUser) {}
}
export class LoadUserHomeWarehouseInfoFail implements Action {
  readonly type = LOAD_USER_HOME_WAREHOUSE_INFO_FAIL;
  constructor(public payload: any) {}
}
export class LoadUserHomeWarehouseInfoSuccess implements Action {
  readonly type = LOAD_USER_HOME_WAREHOUSE_INFO_SUCCESS;
  constructor(public payload: IHomeWarehouse) {}
}

export type UserAction =
  | InitializeUser
  | InitializeUserFail
  | InitializeUserSuccess
  | LoadSecurityObjects
  | LoadSecurityObjectsFail
  | LoadSecurityObjectsSuccess
  | LoadUserHomeWarehouseInfo
  | LoadUserHomeWarehouseInfoFail
  | LoadUserHomeWarehouseInfoSuccess;
