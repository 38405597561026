import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { OktaAuthService } from '@becksdevteam/okta-angular';
import { Store } from '@ngrx/store';
import { selectFlagsmithFeatureFlagsLoaded } from 'app/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

/* This guard checks:
     1. Is the user is authenticated
     2. Waits until Flagsmith feature flags are loaded into the store
        - Flagsmith feature flags are fetched by the flagsmith-feature-flag.effects.ts constructor
*/
export const flagsmithFeatureFlagsGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): Observable<boolean> => {
  const oktaAuthService = inject(OktaAuthService);
  const store = inject(Store);

  return oktaAuthService.isAuthenticated$.pipe(
    filter((isAuthenticated) => isAuthenticated),
    switchMap(() => {
      return store.select(selectFlagsmithFeatureFlagsLoaded).pipe(filter((loaded) => loaded));
    }),
    catchError(() => of(false))
  );
};
