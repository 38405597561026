import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { RollbarModule } from '@becksdevteam/rollbar-angular';

/** Custom Modules */
import { AuthModule } from './auth/auth.module';

/** Interceptors */
import { BlobErrorHttpInterceptor } from './interceptors/blob-error-http.interceptor';

/** Services */
import { CustomRendererService } from './services/custom-renderer.service';
import { LoadActionGuardFactoryService } from './services/load-action-guard-factory.service';
import { StartupService } from './services/startup.service';

import config from 'app/app.config';
import { InlineSVGModule } from 'ng-inline-svg-2';
import packageInfo from '../../../package.json';
import { GlobalErrorHandler } from './classes/global-error-handler.class';
import { ModuleImportOnceGuard } from './classes/module-import-once-guard.class';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    HttpClientJsonpModule,
    AuthModule,
    InlineSVGModule.forRoot(),
    RollbarModule.forRoot({
      accessToken: config.rollbarAccessToken,
      environment: config.name,
      codeVersion: packageInfo.version,
      autoInstrument: {
        log: false
      },
      payload: {
        environment: config.name,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: packageInfo.version,
            // Have Rollbar guess which frames the error was
            // thrown from when the browser does not provide line
            // and column numbers.
            guess_uncaught_frames: true
          }
        }
      }
    })
  ],
  providers: [
    LoadActionGuardFactoryService,
    CustomRendererService,
    StartupService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ]
})
export class CoreModule extends ModuleImportOnceGuard {
  public constructor(@SkipSelf() @Optional() parent: CoreModule) {
    super(parent);
  }
}
