import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import environment from '../../../app/app.config';
import { RequestOptions, ResponseContentType } from '../models/http.interface';
import { SnackbarService } from './snackbar.service';

@Injectable()
export class ApiService {
  apiURL: string;
  notificationApiUrl: string;
  state: RouterStateSnapshot;

  private headers: HttpHeaders;
  private options: RequestOptions;
  private _defaultOptions: object;
  private loginPath: string = `/login`;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _snackbarService: SnackbarService
  ) {
    this.state = _router.routerState.snapshot;
    this.apiURL = environment.apiUrl;
    this.notificationApiUrl = environment.notifications.apiUrl;

    this.headers = new HttpHeaders({
      'Content-Type': ResponseContentType.Json,
      Accept: ResponseContentType.Json
    });

    this._defaultOptions = {
      headers: this.headers,
      observe: 'response',
      responseType: 'json',
      withCredentials: true
    };

    this.options = this._defaultOptions;
  }

  getNotifications(path: string, options?: object): Observable<any> {
    return this._http.get(`${this.notificationApiUrl}/client${path}`, options ? this.customOptions(options) : this.options).pipe(
      map(this.getBody),
      catchError((res) => this.checkForError(res))
    );
  }

  putNotifications(path: string, body): Observable<any> {
    return this._http.put(`${this.notificationApiUrl}/client${path}`, JSON.stringify(body), this.options).pipe(
      map(this.getBody),
      catchError((res) => this.checkForError(res))
    );
  }

  get<T = any>(path: string, options?: object): Observable<T> {
    return this._http.get(`${this.apiURL}${path}`, options ? this.customOptions(options) : this.options).pipe(
      map(this.getBody),
      catchError((res) => this.checkForError(res))
    );
  }

  post<T = any>(path: string, body: any, options?: object): Observable<T> {
    const emptyHeadersOption = { headers: new Headers({}) };
    if (body instanceof FormData) {
      options = options ? Object.assign(options, emptyHeadersOption) : emptyHeadersOption;
    } else {
      body = JSON.stringify(body);
    }

    return this._http.post(`${this.apiURL}${path}`, body, options ? this.customOptions(options) : this.options).pipe(
      map(this.getBody),
      catchError((res) => this.checkForError(res))
    );
  }

  put<T = any>(path: string, body: any): Observable<T> {
    return this._http.put(`${this.apiURL}${path}`, JSON.stringify(body), this.options).pipe(
      map(this.getBody),
      catchError((res) => this.checkForError(res))
    );
  }

  delete(path: string): Observable<any> {
    return this._http
      .delete(`${this.apiURL}${path}`, {
        ...this.options,
        headers: {
          Accept: ResponseContentType.Json
        }
      })
      .pipe(
        map(this.getBody),
        catchError((res: HttpErrorResponse) => this.checkForError(res))
      );
  }

  // HELPER FUNCTIONS
  checkForError(response: HttpErrorResponse) {
    switch (response.status) {
      case 401: {
        this.sendToLogin();
        break;
      }
      case 403: {
        // display a snackbar when a 403 is encountered.
        this._snackbarService.errorWithHandler(
          response.error,
          'Insufficient Access',
          'Some functionality on this page may not work as expected'
        );
        // if 403 error occurs on a resolve and no parent/previous routing has occurred yet
        // send user to app home
        if (!this._router.routerState.snapshot.url) {
          this.sendHome();
        }
        break;
      }
      case 500: {
        // display a snackbar when a 500 error is encountered.
        this._snackbarService.errorWithHandler(response.error, 'Error');
        // if 500 error occurs on a resolve and no parent/previous routing has occurred yet
        // send user to app home
        if (!this._router.routerState.snapshot.url) {
          this.sendHome();
        }
        break;
      }
      case 503: {
        this.sendToLogin();
        break;
      }
    }

    return observableThrowError(() => response.error);
  }

  setHeaders(headers: {}) {
    Object.keys(headers).forEach((header) => this.headers.set(header, headers[header]));
  }

  sendToLogin(originURL?: string) {
    // send along original url if exists, if not send them to login url with no origin
    if (originURL) {
      this._router.navigateByUrl(`${this.loginPath}?originURL=${encodeURIComponent(originURL)}`);
    } else {
      this._router.navigateByUrl(this.loginPath);
    }
  }

  sendHome() {
    this._router.navigate(['/']);
  }

  private getBody(response: HttpResponse<any>) {
    return response.body;
  }

  // merge the options parameters with the default options, overwriting
  // any options in default with the newOptions
  private customOptions(newOptions: object): object {
    const optionsObj: RequestOptions = {};
    Object.assign(optionsObj, { ...this._defaultOptions, ...newOptions });

    return optionsObj;
  }
}
