/* eslint-disable @typescript-eslint/dot-notation */
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import _ from 'lodash';

import { IUser, UserType } from '../../shared/models/user.interface';

import { UserState, userStateKey } from 'app/store/reducers/user.reducer';
import { UserService } from '../../shared/services/user.service';

export const getUserState = createFeatureSelector<UserState>(userStateKey);

export const getUser = createSelector(getUserState, (state: UserState) => state.user);
export const getUserLoaded = createSelector(getUserState, (state: UserState) => state.userLoaded);
export const getUserLoading = createSelector(getUserState, (state: UserState) => state.userLoading);
export const getUserSecurity = createSelector(getUserState, (state: UserState) => state.security);

const _extractFeatures = (user: IUser) => {
  if (user?.securityObjects) {
    const activeCustID: number = user.activeCustID || 14; // make this the real custID
    const custSecurityObject: {} = _.find(user.securityObjects, {
      customerID: activeCustID
    })!;

    return _.flow(
      (fs) => _.map(fs, (featureSet) => _.map(featureSet.features, 'code')),
      _.flatten,
      _.uniq
    )(custSecurityObject['securityObjects']);
  } else {
    return [];
  }
};

export const getUserFeatureCodes = createSelector(getUserState, getUserSecurity, (userState, security) => {
  return _extractFeatures(userState.user);
});

export const getUserIsExternal = createSelector(getUserState, (userState) => {
  return Boolean(userState.user.activeCustID && userState.user.activeCustID !== 0 && userState.user.activeCustID !== 14);
});

export const getUserHasFeatureCode = (role: string) =>
  createSelector(getUser, getUserFeatureCodes, (user) => {
    return UserService.hasRole(user, role);
  });

export const getUserHasFeatureCodes = (roles: string[]) =>
  createSelector(getUser, getUserFeatureCodes, (user) => {
    return UserService.hasRoleFromList(user, roles);
  });

export const getUserIsInternal: MemoizedSelector<object, boolean> = createSelector(getUserState, (userState: UserState) => {
  return Boolean(userState && userState.user && userState.user.type === UserType.AD_INTERNAL);
});

export const getUserHomeWarehouseInfo = createSelector(getUserState, (userState) => {
  if (userState.homeWarehouse.warehouseID) {
    return userState.homeWarehouse;
  }
});

export const getUserIsSeedAdvisor: MemoizedSelector<object, boolean> = createSelector(getUserState, (userState: UserState) => {
  return Boolean(userState && userState.user && userState.user.dsmID && userState.user.atlID == null && userState.user.rbmID == null);
});

export const getUserActiveCustID = createSelector(getUser, (user) => user.activeCustID);

export const getUserIsDealerSalesRep: MemoizedSelector<object, boolean> = createSelector(getUser, (user: IUser) => {
  return Boolean(user.salesRepDlrID);
});

export const getUserDealerSalesRepID: MemoizedSelector<object, number | null> = createSelector(getUser, (user: IUser) => {
  return user.salesRepDlrID && user.activeCustID ? user.activeCustID : null;
});

export const getUserIsDealer: MemoizedSelector<object, boolean> = createSelector(
  getUserIsExternal,
  getUserIsDealerSalesRep,
  (userIsExternal: Boolean, userIsDealerSalesRep) => {
    return Boolean(userIsExternal && !userIsDealerSalesRep);
  }
);

export const getUserDlrSalesRepDlrAsDlrIDQueryParamObj: MemoizedSelector<object, { dlrID: number } | null> = createSelector(
  getUser,
  (user: IUser) => {
    return user.salesRepDlrID && user.activeCustID ? { dlrID: user.activeCustID } : null;
  }
);
