/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { OktaAuthService } from '@becksdevteam/okta-angular';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { Cookie } from 'ng2-cookies';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import environment from '../../../app/app.config';
import * as fromActions from '../../store/actions';
import * as fromReducers from '../../store/reducers';
import { IHomeWarehouse, IUser, IUserPreference, UserRole, UserType } from '../models/user.interface';
import { ApiService } from './api.service';

@Injectable()
export class UserService {
  private readonly _emptyUser: IUser = {
    email: '',
    firstName: '',
    lastName: '',
    pictureUrl: '',
    userName: ''
  };
  private _userID: number;
  private _user: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(this._emptyUser);
  private _userDeSync: BehaviorSubject<IUser | undefined> = new BehaviorSubject<IUser | undefined>(undefined);

  public user$: Observable<IUser> = this._user.asObservable();
  public userDeSync$: Observable<IUser | undefined> = this._userDeSync.asObservable();

  constructor(
    private _apiService: ApiService,
    private _oktaService: OktaAuthService<IUser>,
    private _store: Store<fromReducers.State>
  ) {
    // register on user changes
    this._oktaService.user$
      .pipe(
        withLatestFrom(this.user$, this.userDeSync$),
        filter(([newUserData, currentUser, userDeSync]) => currentUser.userName !== '')
      )
      .subscribe(([newUserData, currentUser, userDeSync]) => {
        if (newUserData.id !== currentUser.id && (!userDeSync || userDeSync.id !== newUserData.id)) {
          // different user was detected
          this._userDeSync.next(newUserData);
        } else {
          // original user detected, after having reacted to a different user
          this._userDeSync.next(undefined);
        }
      });
  }

  public initUser(userData: IUser) {
    this.setUserData(userData);
    this.getUserSecurityObjects().subscribe();
  }

  public setUserData(userData: IUser): void {
    if (userData.id) {
      this._userID = userData.id;
    }
    this._user.next(userData);
  }

  public getCurrentUser(): Observable<IUser> {
    return this.user$;
  }

  public getCurrentUserRole(): UserRole {
    const userData: IUser = this._user.value;
    if (userData.rbmID) {
      return UserRole.RBM;
    } else if (userData.atlID) {
      return UserRole.ATL;
    } else if (userData.dsmID) {
      return UserRole.SA;
    } else if (!userData.dsmID && userData.type === UserType.AD_INTERNAL) {
      return UserRole.INTERNAL_USER;
    } else if (userData.activeCustID !== 14 && userData.type === UserType.AD_EXTERNAL) {
      return UserRole.DEALER;
    } else {
      return UserRole.CUSTOMER;
    }
  }

  public getCurrentUserValue(): IUser {
    return this._user.value;
  }

  public signout() {
    Cookie.delete(environment.authCookieName, '/', '.beckshybrids.com');
    this.clearUserData();
    this._apiService.sendToLogin();
  }

  public clearUserData() {
    this._user.next(this._emptyUser);
  }

  public getUserSecurityObjects() {
    return this._apiService.get(`/user/${this._userID}/security`).pipe(
      tap((secObj) => {
        let user = this._user.getValue();
        user = Object.assign(_.cloneDeep(user), {
          securityObjects: secObj.securityObjects,
          activeCustID: secObj.defaultCustID || 14,
          activeCustBeckType: secObj.defaultBeckType,
          salesRepDlrID: secObj.salesRepDlrID
        });
        this._store.dispatch(new fromActions.InitializeUser(user));
        this._store.dispatch(new fromActions.LoadUserHomeWarehouseInfo(user));
        this._user.next(user);
      })
    );
  }

  public fetchUserSecurity(user: IUser) {
    const { beckUserID } = user;
    return this._apiService.get(`/user/${beckUserID}/security`);
  }

  public static hasRole(user: IUser, roleCode: string): boolean {
    const featureCodes: string[] = this._extractFeatures(user);
    return featureCodes.indexOf(roleCode) >= 0;
  }

  public static hasRoleFromList(user: IUser, roles: string[]): boolean {
    const featureCodes: string[] = this.getUniqueFeatureCodes(user);
    return Boolean(_.intersection(featureCodes, roles).length > 0);
  }

  public static getUniqueFeatureCodes(user: IUser): string[] {
    const featureCodes: string[] = this._extractFeatures(user);
    return featureCodes;
  }

  private static _extractFeatures(user: IUser): string[] {
    if (user?.securityObjects) {
      const activeCustID: number = user.activeCustID || 14; // make this the real custID
      const custSecurityObject: {} = _.find(user.securityObjects, {
        customerID: activeCustID
      })!;

      return _.flow(
        (fs) => _.map(fs, (featureSet) => _.map(featureSet.features, 'code')),
        _.flatten,
        _.uniq
      )(custSecurityObject['securityObjects']) as string[];
    } else {
      return [];
    }
  }

  public saveUserPreference<T = any>(settingCode: string, settingValue: any, salesYear?: string): Observable<IUserPreference<T>> {
    return this._apiService.put(`/user/settings?settingCode=${settingCode}${salesYear ? '&salesYear=' + salesYear : ''}`, settingValue);
  }

  public fetchUserPreference<T = any>(settingCode: string, salesYear?: string): Observable<IUserPreference<T>> {
    return this._apiService.get(`/user/settings?settingCode=${settingCode}${salesYear ? '&salesYear=' + salesYear : ''}`);
  }

  public fetchUserHomeWarehouseInternal(): Observable<IHomeWarehouse> {
    return this._apiService.get(`/customers/homeWarehouse`);
  }

  public fetchUserHomeWarehouseExternal(custID: number): Observable<IHomeWarehouse> {
    return this._apiService.get(`/customers/homeWarehouse/${custID}`);
  }
}
